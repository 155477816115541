import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import {Subscription, timer} from 'rxjs'

@Component({
  selector: 'sessioninactivity',
  templateUrl: './sessioninactivity.component.html',
  styleUrl: './sessioninactivity.component.css'
})
export class SessioninactivityComponent implements OnInit {
  constructor(private profileService: ProfileService,private loggerService: LoggerService) { }

  isVisible = false;
  countdownText = '';
  private popupSub!: Subscription;
  private countdownSub!: Subscription;
  
  ngOnInit() {
    this.popupSub = this.profileService.getPopupState().subscribe(visible => {
      this.isVisible = visible;
    });

    this.countdownSub = this.profileService.getCountdownValue().subscribe(seconds => {
      this.countdownText = this.formatTime(seconds);
    });
  }

  formatTime(seconds: number | null): string {
    if (seconds === null) return '';
    return seconds >= 60 ? `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(2, '0')} minutes`
                         : `${seconds} seconds`;
  }

  closePopup() {
    this.profileService.closePopup();
  }

  logout() {
    this.profileService.logout();
  }

  ngOnDestroy() {
    this.popupSub.unsubscribe();
    this.countdownSub.unsubscribe();
  }
  // onLogoutClick() {
  //   this.profileService.logoutUser().then((data: any) => {
  //     this.loggerService.logout();
  //     window.location.href = data;
  //     localStorage.clear();
  //   });
  // }


}
