<div class="popup-greybackground" *ngIf="isVisible"></div>

<div class="popup-container" *ngIf="isVisible">
  <div class="popup">
    <div style="display:flex;flex-direction:row;justify-content: center;"><i class="fa fa-exclamation-circle" style="color: orange !important;font-size: 60px;"
        aria-hidden="true"></i></div>
    <div class="popup-text">
      <div style="font-weight: bold;font-size: 24px;">Session ending soon!</div>
      
      <div style="font-size: 14px;">your session will be terminated in</div>
      <div style="font-weight: bold;font-size: 14px;">{{countdownText}}</div>
    </div>
    <div style="display:flex;flex-direction:row;justify-content: center;">
      <button class="btn" (click)="closePopup()" style="border-radius: 2px;border: 2px solid #0470c5;font-size: 16px; color: #0470c5!important;background-color: transparent;font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
    margin: 0 10px;
    padding: 10px 20px;
    width: 95px;
    height: 45px;
    ">
        Close
      </button>
      <button class="btn btn-primary" (click)="logout()" style="font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;border-radius: 2px;background: #0470c5;font-size: 16px;color: #fff;    margin: 0 10px;
    padding: 10px 20px;width: 95px;height: 45px;">
        Logout
      </button>
    </div>
  </div>
</div>