import { Component, ElementRef, OnInit, TemplateRef, ViewChild, EventEmitter, Output, Input ,NO_ERRORS_SCHEMA} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TableHeaderCellData, TableRowData, TableCellData, TableClickEvent, DataTableType } from 'src/app/modals/datatable/datatable';
import { State, PrescriberSearchCriteria } from 'src/app/modals/prescriberSearch/prescriberviewmodal';
import { PrescriberSearchWebRequest, PrescriberFavoriteWebRequest, PrescriberDetailWebRequest } from 'src/app/modals/prescriberSearch/prescriberSearchweberequest';
import { PrescriberSearchItem, PrescriberSearchRootObject, PrescriberFavoriteResponse, PrescriberFavoriteWebResponse, PrescriberDetailWebResponse, PrescriberDetailItem, PrescriberAlternateAddress, PrescriberAddressBook, PrescriberInfoList, PrescriberIdentifier } from 'src/app/modals/prescriberSearch/prescriberSearchwebresponse';
import { PrescriberSearchService } from 'src/app/services/prescriberSearch/prescriber-search.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { APPCONSTANTS, EPA_STATUS, EPA_TRANSACTIon_STATUS, PRESCRIBER_QUALIFIER } from 'src/app/constants/appconstants';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ResponseRootObj, Status } from 'src/app/modals/webresponsestatus';
import { DrugSearchItem, SelectedMedicine } from 'src/app/modals/drugSearch/drugsearchwebresponse';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { PAInitiationWebRequest } from 'src/app/modals/epa/priorauthrequest';
import { EpaprocessorService } from 'src/app/services/epa/epaprocessor.service';
import { EpaInitiationAckWebResponse, EpaInitiationResponse, PARefIDWebResponse, ResubmitSavedPA } from 'src/app/modals/epa/priorauthresponse';
import { Subscription, interval, timer } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { MembersearchService } from 'src/app/services/membersearch/membersearch.service';
import { CheckCoveragePageService } from 'src/app/services/drugsearch/check-coverage-page.service';
import { QuestionsetViewmodal } from 'src/app/modals/epa/questionsetviewmodal';

enum FavouriteActionErrorType {
  NO_ERROR = 0,
  RETRIEVE_ERROR = 1,
  UNFAVOURITE_ERROR = 2
}

@Component({
  selector: 'app-select-prescriber',
  templateUrl: './select-prescriber.component.html',
  styleUrls: ['./select-prescriber.component.css']
})

export class SelectPrescriberComponent implements OnInit {

  @Output() intiateEPA = new EventEmitter<PrescriberSearchItem | undefined>();

   @Output() selectNPI = new EventEmitter<any>();

  @Output() errorHandling = new EventEmitter<any>();

  @Input() addedPrescribersList:any;

  @Output() prescriberSearchHistorie = new EventEmitter<any>();


  
  favouriteActionError: FavouriteActionErrorType = FavouriteActionErrorType.NO_ERROR;

  private _prescribers !: PrescriberFavoriteWebResponse[];

  public get prescribers(): PrescriberFavoriteWebResponse[] {
    return this._prescribers;
  }
  public set prescribers(v: PrescriberFavoriteWebResponse[]) {
    this._prescribers = v;
    this.currentPageNumber = 1;
    this.loadPrescriberSearchItems(this.getCurrentPageMembers());
  }

  @ViewChild("favouriteErrorTemplate")
  favouriteErrorTemplateRef?: TemplateRef<HTMLElement>;

  @ViewChild("selectFaxModal")
  selectFaxModal!: ElementRef<HTMLElement>;

  @ViewChild("PresSelectError")
  PresSelectError!: ElementRef<HTMLElement>;

  @ViewChild("paInitiationTemplate")
   private paInitiationTemplateRef!:TemplateRef<HTMLElement>;

   @ViewChild("paInitiationErrorTemplate")
   private paInitiationErrorTemplateRef!:TemplateRef<HTMLElement>;
   
   @ViewChild("paInitiationFaxFormTemplate")
  private paInitiationFaxFormTemplateRef!:TemplateRef<HTMLElement>;

  @ViewChild("cancelPAConfirmation")
  cancelPAConfirmationModal?:ElementRef<HTMLElement>;


  blnSelectFax: boolean = false;

  private firstHeader: TableHeaderCellData = new TableHeaderCellData();
  private secondHeader: TableHeaderCellData = new TableHeaderCellData();
  private thirdHeader: TableHeaderCellData = new TableHeaderCellData();
  private forthHeader: TableHeaderCellData = new TableHeaderCellData();


  constructor(private titleService: Title, private router: Router,
    private prescriberSearchService: PrescriberSearchService, private logger: LoggerService,
    private memberHelper: MembersearchService,
    private trailclaimadjudicationHelper: CheckCoveragePageService,
    private modalService: NgbModal, private profileService: ProfileService, private epaProcessor: EpaprocessorService,) {
    this.titleService.setTitle("PreCheck MyScript - Access Manager - Add Prescribersss");



    this.firstHeader.content = "Name";
    this.firstHeader.sortable = true;
    this.firstHeader.sortAscending = true;
    this.firstHeader.selectedForSort = true;
    this.secondHeader.content = "Primary Address";
    this.thirdHeader.content = "NPI";
    this.forthHeader.content = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";


    this.prescriberSearchHeaders = [];
    this.prescriberSearchHistory = [];

    this.prescriberSearchHeaders.push(this.firstHeader);
    this.prescriberSearchHeaders.push(this.secondHeader);
    this.prescriberSearchHeaders.push(this.thirdHeader);
    this.prescriberSearchHeaders.push(this.forthHeader);
    debugger;
    this.states = this.prescriberSearchService.states;
    console.log(this.states);


  }

  states: State[];
filterStatus: string = '';

  // Data Table variables and functions
  prescriberSearchHeaders: TableHeaderCellData[];
  prescriberSearchHistory: TableRowData<PrescriberSearchItem>[];

  datatableType: DataTableType = DataTableType.HIGHLIGHT_ROWS;
  activeTab: number = 1;
  submittedNPIForm: boolean = false;
  submittedNPINameForm: boolean = false;
  prescriberList: PrescriberSearchItem[] = [];
  savedPrescrbersBKArry: string[] = new Array();
  recentPrescrbersArry: string[] = new Array();
  recentSearches: PrescriberSearchItem[] = new Array();
  savedSearches!: PrescriberSearchItem[];
  identifiers!: PrescriberIdentifier[];
  timeoutError!:string;
  nomatchesFound!:string;

  currentPageNumber: number = 1;
  recordsPerPage: number = environment.paginationRecordsPerPage;
  totalPagesCount: number = -1;
  errorText = "";
  message = "";
  savedMessage = "";
  prescriberDetailList!: PrescriberDetailItem;
  selectedNPI: any;
  prescriberFaxDetails: any=[];
  errorMessage!: string;
  SavedAddressBkDetails!: PrescriberInfoList[];
  userRole:any;

  memberDetails:any;
  drugDetails!: SelectedMedicine;
  claimresponse!:any;
  PaRefID:any;
  paReferenceId:any;
  counter = 1;
  trailAdjuducicationError = "";
  private subscription!:any;
  PAResponse:any ;
  blnepaquestionPage:boolean = false;
  epaState:EPA_STATUS = EPA_STATUS.PA_NOT_STARTED;
  epaTimerSubscription!:Subscription;
  epaHoursRemaining!:number;
  epaMinutesRemaining!:number;
  epaSecondsRemaining!:number;




  @ViewChild("claimSummaryProgress")
  claimSummaryProgress?: ElementRef<HTMLElement>;


  @ViewChild("LoadingContent")
  loadingContent?: ElementRef<HTMLElement>;

  @ViewChild("recentPrescribers")
  recentPrescribers?: ElementRef<HTMLElement>;

  @ViewChild("savedPrescribers")
  savedPrescribers?: ElementRef<HTMLElement>;

  @ViewChild("actionRemove", { static: true })
  actionRemove?: TemplateRef<any>

  @ViewChild("actionSelect", { static: true })
  actionSelect?: TemplateRef<any>

  @ViewChild("errorPopup", { static: true })
  errorPopupTemplateRef?: TemplateRef<HTMLElement>;


  searchPrescriberNPIForm = new FormGroup({
    NPI: new FormControl('', { validators: [Validators.required, Validators.minLength(10)], updateOn: 'blur' })
  });

  searchPrescriberNameForm = new FormGroup({
    lastName: new FormControl('', { validators: [Validators.required, Validators.minLength(2)], updateOn: 'blur' }),
    firstName: new FormControl('', { validators: [], updateOn: 'blur' }),
    city: new FormControl('', { validators: [], updateOn: 'blur' }),
    state: new FormControl('', { validators: [Validators.required], updateOn: 'blur' })
  });

  get NPI() {
    return this.searchPrescriberNPIForm.get('NPI');
  }

  get lastName() {
    return this.searchPrescriberNameForm.get('lastName');
  }

  get firstName() {
    return this.searchPrescriberNameForm.get('firstName');
  }

  get city() {
    return this.searchPrescriberNameForm.get('city');
  }

  get state() {
    return this.searchPrescriberNameForm.get('state');
  }

  ngOnInit(): void {
    debugger;
    this.PaRefID = sessionStorage.getItem('paReferenceID');
    this.paReferenceId = sessionStorage.getItem('paRefID');
    if((sessionStorage.getItem("blnSelectPrescriberInCheckCoverage")!=undefined && sessionStorage.getItem("blnSelectPrescriberInCheckCoverage")=="true") || (sessionStorage.getItem("blnSelectPrescriberInEPACheckCoverage")!=undefined && sessionStorage.getItem("blnSelectPrescriberInEPACheckCoverage")=="true")){
      this.memberDetails = this.memberHelper.getSelectedMember();
      this.drugDetails = JSON.parse(""+sessionStorage.getItem("selectedMedicine"));

    }else{
      sessionStorage.removeItem("selectedMedicine");
    }
    this.userRole = this.profileService.USER_ROLE;
    sessionStorage.setItem("lastPageBeforeReload", this.router.url);

    debugger;
    this.SavedAddressBkDetails = JSON.parse("" + sessionStorage.getItem("savedAddressBookPresdetails"));
    //  this.savedPrescrbersBKArry = this.SavedAddressBkDetails;
    console.log(this.SavedAddressBkDetails);


    const prescriberresultsinsession: PrescriberSearchItem[] | undefined = this.prescriberSearchService.readPrescriberResults();

    if (prescriberresultsinsession != undefined) {
      debugger;

      this.prescriberList = prescriberresultsinsession;
      this.currentPageNumber = 1;

      const searchCriteria: PrescriberSearchCriteria | undefined = this.prescriberSearchService.readPrescriberSearchCriteria();
      if (searchCriteria != undefined) {
        debugger;
        if (searchCriteria.prescriberId.length > 0) {
          //Last search is NPI search.
          this.activeTab = 1;
          this.NPI?.setValue(searchCriteria.prescriberId.toString());
        } else {
          this.activeTab = 2;
          this.lastName?.setValue(searchCriteria.lastName);
          this.firstName?.setValue(searchCriteria.firstName);
          this.city?.setValue(searchCriteria.prescriberCity);
          debugger;
          if (this.states.findIndex((state: State) => state.code == searchCriteria.prescriberState) != -1) {
            this.state?.setValue(this.states.filter((state: State) => state.code == searchCriteria.prescriberState)[0].code);
          }
        }
      }

      this.prescriberSearchService.savedPrescribers.subscribe(() => {
        this.updateTotalPagesCount();
        this.loadPrescriberSearchItems(this.getCurrentPageMembers());
      });
      // this.updateTotalPagesCount();
      // this.loadPrescriberSearchItems(this.getCurrentPageMembers());
      this.modalService.dismissAll();
    }

  }

  submitNPIForm() {
    this.submittedNPIForm = true;

    this.logger.userAction("Search Prescriber via NPI.");

    if (this.searchPrescriberNPIForm.valid) {
      var prescriber = new PrescriberSearchWebRequest();
      prescriber.prescriberId = "" + this.NPI?.value;
      //console.log("NPI", this.NPI?.value);
      prescriber.prescriberIdQualifier = PRESCRIBER_QUALIFIER.NPI;

      this.prescriberSearchHistory = [];
      this.modalService.open(this.loadingContent, { modalDialogClass: 'content', windowClass: 'loadingContent',backdrop:'static' });

      this.prescriberSearchService.clearSession();

      this.prescriberSearchService.fetchPrescriberSearch(prescriber).then((data: PrescriberSearchRootObject) => {
        debugger;
        if (data.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
          this.prescriberList = data.response.prescriberSearchItems;
           //code to remove npis which are not of qualifier "01"
           for (let i = 0; i < this.prescriberList.length; i++) {
            {
              for (let j = 0; j < this.prescriberList[i].identifiers.length; j++) {
                if (this.prescriberList[i].identifiers[j].qualifier != '01') {
                  this.prescriberList[i].identifiers.splice(j, 1);
                  j = j - 1;
                }
              }
            }
          }
          this.sortPrescriber();
          this.prescriberList = this.prescriberList.slice(0, 100);
          this.currentPageNumber = 1;
          //console.log("Prescriber Length:" + this.prescriberList.length);
          this.updateTotalPagesCount();
          this.loadPrescriberSearchItems(this.getCurrentPageMembers());
          this.modalService.dismissAll();

          const searchCriteria: PrescriberSearchCriteria = new PrescriberSearchCriteria();
          searchCriteria.prescriberId = "" + this.NPI?.value;
          searchCriteria.prescriberIdQualifier = prescriber.prescriberIdQualifier;

          this.prescriberSearchService.storePrescriberSearchCriteria(searchCriteria);
          this.prescriberSearchService.storePrescriberResults(this.prescriberList);
          if (this.recentSearches.length == 0) {
            this.recentSearches.push(this.prescriberList[0]);
            sessionStorage.setItem("recentPrescriberSearches", JSON.stringify(this.recentSearches));
            //console.log("recentsearches",this.recentSearches);
          } else if (this.recentSearches.map((data) => data.identifiers[0].id).indexOf(this.prescriberList[0].identifiers[0].id) == -1) {
            this.recentSearches.push(this.prescriberList[0]);
            sessionStorage.setItem("recentPrescriberSearches", JSON.stringify(this.recentSearches));
            //console.log("recentsearches",this.recentSearches);
          }
        }
         else {
          this.modalService.dismissAll();
          this.prescriberList = [];
          this.currentPageNumber = 1;
          this.totalPagesCount = 0;
          //console.log("Results Not found");
        }
      }, (error: any) => {
        debugger;
        this.modalService.dismissAll();
         if(error.status.respCode==APPCONSTANTS.API_RESPONSE_NO_RECORDS){
          debugger;
          sessionStorage.setItem("nomatchingrecordsvalue","true");
          this.nomatchesFound = ""+sessionStorage.getItem("nomatchingrecordsvalue");
          this.errorHandling.emit({matchFound:this.nomatchesFound,timeOut:this.timeoutError});
        }
         if(error.status.respCode=="504"){
          debugger;
          sessionStorage.setItem("timeoutErrorvalue","true");     
          this.timeoutError = ""+sessionStorage.getItem("timeoutErrorvalue"); 
          this.errorHandling.emit({matchFound:this.nomatchesFound,timeOut:this.timeoutError});
        }
      });
    }
  }

  submitNPINameForm() {
    //console.log("name search");
    this.logger.userAction("Search Prescriber via Presciber Details.");
    this.submittedNPINameForm = true;
    if (this.searchPrescriberNameForm.valid) {
      this.prescriberSearchHistory = [];
      const prescriber: PrescriberSearchWebRequest = new PrescriberSearchWebRequest();
      prescriber.lastName = "" + this.lastName?.value;
      prescriber.firstName = "" + this.firstName?.value;
      prescriber.prescriberState = "" + this.state?.value;
      prescriber.prescriberCity = "" + this.city?.value;
      prescriber.prescriberState = "" + this.state?.value;

      this.modalService.open(this.loadingContent, { modalDialogClass: 'content',windowClass:'loadingContent',backdrop:'static' });
      this.prescriberSearchService.fetchPrescriberSearch(prescriber).then((data: PrescriberSearchRootObject) => {
        //console.log(data);
        if (data.status.respCode == APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
          this.prescriberList = data.response.prescriberSearchItems;
            //code to remove npis which are not of qualifier "01"
            for (let i = 0; i < this.prescriberList.length; i++) {
              {
                for (let j = 0; j < this.prescriberList[i].identifiers.length; j++) {
                  if (this.prescriberList[i].identifiers[j].qualifier != '01') {
                    this.prescriberList[i].identifiers.splice(j, 1);
                    j = j - 1;
                  }
                }
              }
            }
          this.sortPrescriber();
          this.currentPageNumber = 1;
          this.prescriberList = this.prescriberList.slice(0, 100);
          //console.log("Prescriber Length:" + this.prescriberList.length);
          this.updateTotalPagesCount();
          this.loadPrescriberSearchItems(this.getCurrentPageMembers());
          this.modalService.dismissAll();

          const searchCriteria: PrescriberSearchCriteria = new PrescriberSearchCriteria();
          searchCriteria.firstName = "" + this.firstName?.value;
          searchCriteria.lastName = "" + this.lastName?.value;
          searchCriteria.prescriberCity = "" + this.city?.value;
          searchCriteria.prescriberState = "" + this.state?.value;

          this.prescriberSearchService.storePrescriberSearchCriteria(searchCriteria);
          this.prescriberSearchService.storePrescriberResults(this.prescriberList);

        } 
         else {
          this.modalService.dismissAll();
          this.prescriberList = [];
          this.currentPageNumber = 1;
          this.totalPagesCount = 0;
          //console.log("Results Not found");
        } 
      },(error: any) => {
        debugger;
        this.modalService.dismissAll();
         if(error.status.respCode==APPCONSTANTS.API_RESPONSE_NO_RECORDS){
          debugger;
          sessionStorage.setItem("nomatchingrecordsvalue","true");
          this.nomatchesFound = ""+sessionStorage.getItem("nomatchingrecordsvalue");
          this.errorHandling.emit({matchFound:this.nomatchesFound,timeOut:this.timeoutError});
        }
         if(error.status.respCode=="504"){
          debugger;
          sessionStorage.setItem("timeoutErrorvalue","true");  
          this.timeoutError = ""+sessionStorage.getItem("timeoutErrorvalue"); 
          this.errorHandling.emit({matchFound:this.nomatchesFound,timeOut:this.timeoutError});
        }
      });
    }
  }

  clearNPIForm() {
    //console.log(" clear");
    this.searchPrescriberNPIForm.reset();
    this.submittedNPINameForm = false;
    this.submittedNPIForm = false;
  }

  clearNPINameForm() {
    //console.log(" clear");
    debugger;
    this.searchPrescriberNameForm.controls['city'].setValue("");
    this.searchPrescriberNameForm.controls['state'].setValue("");
    this.searchPrescriberNameForm.controls['lastName'].setValue("");
    // this.searchPrescriberNameForm.reset();
    this.searchPrescriberNameForm.controls['firstName'].setValue("");
    this.submittedNPINameForm = false;
    this.submittedNPIForm = false;
  }

  focussedNPI = false;
  onFocusNPI() {
    this.focussedNPI = !this.focussedNPI;
  }

  focussedLastName = false;
  onFocusLastName() {
    this.focussedLastName = !this.focussedLastName;
  }

  focussedState = false;
  onFocussedState() {
    this.focussedState = !this.focussedState;
  }
  @ViewChild("errorContent")
  content?: ElementRef<HTMLElement>;

  @ViewChild("NameTemplate", { static: true })
  NameTemplate?: TemplateRef<any>

  @ViewChild("AddressTemplate", { static: true })
  AddressTemplate?: TemplateRef<any>

  @ViewChild("NPITemplate", { static: true })
  NPITemplate?: TemplateRef<any>

  @ViewChild("SNameTemplate", { static: true })
  SNameTemplate?: TemplateRef<any>

  @ViewChild("SAddressTemplate", { static: true })
  SAddressTemplate?: TemplateRef<any>

  @ViewChild("SNPITemplate", { static: true })
  SNPITemplate?: TemplateRef<any>

  @ViewChild("prescriberSaveTemplate", { static: true })
  prescriberSaveTemplateRef?: TemplateRef<any>;

  @ViewChild("actionPrescriberSearchSelect", { static: true })
  actionPrescriberSearchSelect?: TemplateRef<any>

  @ViewChild("RNameTemplate", { static: true })
  RNameTemplate?: TemplateRef<any>

  @ViewChild("RAddressTemplate", { static: true })
  RAddressTemplate?: TemplateRef<any>

  @ViewChild("RNPITemplate", { static: true })
  RNPITemplate?: TemplateRef<any>


  private loadPrescriberSearchItems(rawData: PrescriberSearchItem[]): void {
    this.prescriberSearchHistory = [];
    rawData.forEach((item: PrescriberSearchItem) => {
      // this.identifiers = item.identifiers;
      // console.log(this.identifiers);
      // for(let i=0;i<item.identifiers.length;i++)
      // if (item.identifiers[i].id.length == 10) {
      const row: TableRowData<PrescriberSearchItem> = new TableRowData<PrescriberSearchItem>();

      const firstCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      firstCell.data = item;
      firstCell.template = this.NameTemplate;
      row.cells.push(firstCell);

      const secondCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      secondCell.data = item;
      secondCell.template = this.AddressTemplate;
      row.cells.push(secondCell);

      const thirdCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      thirdCell.data = item;
      thirdCell.template = this.NPITemplate;
      row.cells.push(thirdCell);

      const forthCell: TableCellData<PrescriberSearchItem> = new TableCellData();
      forthCell.data = item;
      forthCell.template = this.actionPrescriberSearchSelect;
      row.cells.push(forthCell);

      this.prescriberSearchHistory.push(row);

    });
    this.prescriberSearchHistorie.emit(this.prescriberSearchHistory);
    //console.log("prescriber array");
    //console.log(this.prescriberSearchHistory);
  }

  onPrescriberHeaderClick(headerClickEvent: TableClickEvent<TableHeaderCellData>) {
    if (headerClickEvent.cell != undefined) {
      this.sortPrescriber();
      this.loadPrescriberSearchItems(this.getCurrentPageMembers());
    }
  }

  onPrescriberCellClick(cellData: TableClickEvent<PrescriberSearchItem>) {
    debugger;
    // if (this.userRole==12 && (cellData.column == 0 || cellData.column == 1 || cellData.column == 2 || cellData.column == 3)) {
        //need to check how to do checkcoverage here.
        // selectedMedicineObject.selectedPrescriber.
    if((sessionStorage.getItem("blnSelectPrescriberInCheckCoverage")!=undefined && sessionStorage.getItem("blnSelectPrescriberInCheckCoverage")=="true")){
        if(cellData.cell?.data!=undefined)
          this.drugDetails.selectedPrescriber=cellData.cell?.data;
        sessionStorage.setItem("selectedMedicine", JSON.stringify(this.drugDetails));
        sessionStorage.setItem('selectedPrescriber',JSON.stringify(cellData.cell?.data));
        this.checkCoverage();
}
 else if((sessionStorage.getItem("blnSelectPrescriberInEPACheckCoverage")!=undefined && sessionStorage.getItem("blnSelectPrescriberInEPACheckCoverage")=="true")){
  if(cellData.cell?.data!=undefined)
    this.drugDetails.selectedPrescriber=cellData.cell?.data;
  sessionStorage.setItem("selectedMedicine", JSON.stringify(this.drugDetails));
  sessionStorage.setItem('selectedPrescriber',JSON.stringify(cellData.cell?.data));
  this.initiatePA();
  
 }
    else{
      this.addprescriberstoList(cellData.cell?.data);
    }
    //   //console.log("NO Redirect");
    //   //favourite or unfavourite column
    //   if (cellData.cell != undefined && cellData.cell.data != undefined) {
    //     // if (cellData.cell.data.isFavorite) {
    //     //   //unfavourite
    //     //   this.logger.userAction("Removing Favourite Prescriber");
    //     //   this.removeFavouritePrescriber(cellData.cell.data);
    //     // } else {
    //     //   //favourite
    //     //   this.logger.userAction("Saving Prescriber");
    //     //   this.savefavourite(cellData.cell.data);
    //     // }
    //   }
    // } else if (sessionStorage.getItem('pageType') != undefined && sessionStorage.getItem('pageType') == 'resubmitEPA') {
    //   this.intiateEPA.emit(cellData.cell?.data);
    // } else {
    //   //console.log("Redirect");

    //   this.prescriberSearchService.storeSelectedPrescriber(cellData.cell?.data);
    //   //console.log(cellData.cell?.data);
    //   this.goto();

    //  else{
    //   this.addprescriberstoList(cellData.cell?.data);
    //  }

  }

  goto() {
    //console.log("GOto")
    if(this.profileService.loginuser.prescriber?.roleTypeId=="20")
    this.router.navigateByUrl("/prescribers/manage");
    else{
      this.cancelRequest();
    }
  }
  cancelRequest(){
    this.modalService.open(this.cancelPAConfirmationModal,{size:'md',backdrop:'static'});
  }
  cancel(){
    this.router.navigateByUrl("/home");
  }
  onPageChange(pageNumber: any) {
    this.currentPageNumber = pageNumber;
    this.loadPrescriberSearchItems(this.getCurrentPageMembers());
  }

  private getCurrentPageMembers(): PrescriberSearchItem[] {

    if (this.prescriberList.length <= this.recordsPerPage) {
      return this.prescriberList;
    }
    else {
      const startIndex: number = (this.currentPageNumber - 1) * this.recordsPerPage;
      const endIndex: number = (this.currentPageNumber * this.recordsPerPage);
      let result = this.prescriberList.slice(startIndex, endIndex);
      return result;
    }

  }

  private updateTotalPagesCount(): void {
    this.totalPagesCount = (this.prescriberList.length % this.recordsPerPage) != 0 ? Math.floor(this.prescriberList.length / this.recordsPerPage) + 1 : Math.floor(this.prescriberList.length / this.recordsPerPage);
  }

  private sortPrescriber(): void {
    this.prescriberList.sort((member1: PrescriberSearchItem, member2: PrescriberSearchItem) => {
      if (this.firstHeader.sortAscending) {
        const result = member1.firstName.localeCompare(member2.firstName);
        return result !== 0 ? result : member1.lastName.localeCompare(member2.lastName);
      } else {
        const result = member2.firstName.localeCompare(member2.firstName);
        return result !== 0 ? result : member1.lastName.localeCompare(member2.lastName);
      }
    });
  }

 

  addprescriberstoList(item:PrescriberSearchItem | undefined){
    debugger;
     this.selectNPI.emit(item);
  //  console.log(item.primaryDegree);
  //  this.modalService.dismissAll();
   
  }

  checkCoverage() {
    this.logger.userAction("Check Coverage");
    debugger;
    this.modalService.open(this.claimSummaryProgress, { modalDialogClass: 'claimSummaryProgress' ,windowClass:'loadingContent1',backdrop:'static'});
    this.trailclaimadjudicationHelper.checkCoverage(this.drugDetails).then((data: SelectedMedicine) => {
      debugger;
      if (data) {
        this.counter = 2;
        // this.selectedMedicine.forEach((item: SelectedMedicine) => {
          // this.selectedMedicine.memberDetailResponse = this.memberResponse;
        // });
        // sessionStorage.setItem("selectedMedicineList", JSON.stringify(this.selectedMedicineObject));

        // this.modalService.dismissAll();
        // this.router.navigateByUrl("/summary");
        // Adding delay of 2 sec before moving to summary page
        if (data.trailadjudicationwebresponse.status.respCode != APPCONSTANTS.API_RESPONSE_SUCCESS_CODE) {
          this.modalService.dismissAll();
          //this.errorPopupText = ""+data[0].trailadjudicationwebresponse.status.responseDescriptionCode;
          this.modalService.open(this.errorPopupTemplateRef, { modalDialogClass: 'errorModalDefault', size: 'xs' ,backdrop:'static'});
        }else{
        const source = interval(2000);
        this.subscription = source.subscribe(() => {
          this.counter++;
          //console.log(this.counter);
          if (this.counter == 4) {
            //console.log(this.counter);
            this.subscription.unsubscribe();
            this.modalService.dismissAll();
            console.log("Moving to Summary:");
            console.log(this.drugDetails);
            this.drugDetails=data;
            sessionStorage.setItem("selectedMedicine",JSON.stringify(this.drugDetails));
              this.router.navigateByUrl("/PriorAuthchecker/summary");
          }
        });
      }
      }
    }, (error: any) => {

    }).catch((error: any) => {

    });


    }


    initiatePA():void{ 
      debugger;
      this.claimresponse = this.drugDetails;
      const webrequest:PAInitiationWebRequest= this.epaProcessor.extractPAInitiationRequestFromSelectMadicine(this.claimresponse);
      debugger;
      webrequest.pAReferenceID = this.PaRefID;
      // webrequest.prescriber = this.claimresponse.selectedPrescriber;
      this.modalService.open(this.paInitiationTemplateRef,{size:'md',backdrop:'static'});
      this.epaProcessor.submitPAInitiationRequest(webrequest).then((response:EpaInitiationAckWebResponse)=>{
       debugger;
      //console.log("firstcall"+response);
      this.epaState = EPA_STATUS.PA_INITIATION_SUCCESS;
      //Below timeout is for initial delay before start pooling the DB for Questionset availability.
      setTimeout(()=>{
        this.PAResponse = JSON.parse(""+sessionStorage.getItem('PAResponse'));
        console.log(this.PAResponse);
        this.startPoolingQuestionSet(this.PAResponse);
      },1000);
    },(error:any)=>this.handleAPIError(error)).catch((error:any)=>this.handleAPIException(error));
      }
      
      private startPoolingQuestionSet(paRef:PARefIDWebResponse):void{
        debugger;
        this.epaState = EPA_STATUS.PA_QUESTIONNARIE_CHECK;
        this.epaProcessor.poolPAInitiationResponse(""+paRef.paRefID).then((response:EpaInitiationResponse)=>{
          this.epaState = EPA_STATUS.PA_QUESTIONNARIE_RETRIVED;
          const epaQuestions: QuestionsetViewmodal = this.epaProcessor.extactQuestionSetModalFromSavedPA(response.paInitiationResponseWeb.body);
          epaQuestions.paReferenceID.completePaRefId = paRef.paRefPrefix+paRef.paRefID;
          this.epaProcessor.storeQuestionSetViewModal(epaQuestions);
          const $timer:any = timer(0,1000);
          this.epaTimerSubscription = $timer.subscribe((value:any)=>{
            this.showEpaTimeline(epaQuestions.deadlineForReply);
          });
        },(error:any)=>this.handleAPIError(error)).catch((error:any)=>this.handleAPIException(error));
      }
    
      private showEpaTimeline(deadlineForReply:number):void{
        this.epaState = EPA_STATUS.PA_QUESTIONNARIE_TIMELINE;
        const deadLine: Date = new Date(deadlineForReply);
        //Removed 1 minute grace period to submit the questionset.
        deadLine.setMinutes(deadLine.getMinutes()-1);
        const today: Date = new Date();
        
        if(deadLine instanceof Date && !isNaN(deadLine.valueOf())){
          //valid date.
          let differenceinmilliseconds:number= deadLine.getTime() - today.getTime();
          
          this.epaHoursRemaining = parseInt((differenceinmilliseconds/(1000*60*60)).toString());
          differenceinmilliseconds = differenceinmilliseconds - (this.epaHoursRemaining * 60 * 60 * 1000);
    
          this.epaMinutesRemaining = parseInt((differenceinmilliseconds/(1000*60)).toString());
          differenceinmilliseconds = differenceinmilliseconds - (this.epaMinutesRemaining * 60 * 1000);
    
          this.epaSecondsRemaining = parseInt((differenceinmilliseconds/1000).toString());
        }
    
      }
  
      private handleAPIException(error:any):void{
        this.epaState = EPA_STATUS.PA_NOT_STARTED;
        if(typeof error == 'object' &&  (error.status==-1 || error.status==0)){
          //errorhtmlmodal:
          this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
          this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md',backdrop:'static'});
        } else {
          //errorhtmlmodal:
          this.errorMessage="An error occurred. Please call customer service at<br/>1-866-842-3278.";
          this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md',backdrop:'static'});
        }
      }
  
      private handleAPIError(error:any):void{
        debugger;
        this.epaState = EPA_STATUS.PA_NOT_STARTED;
        this.modalService.dismissAll();
        if(typeof error == 'object'){
          if(typeof error=="object" && (error.status==-1 || error.status==0)){
            //errorhtmlmodal:
            this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
            this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md',backdrop:'static'});
          } else {
            const message:string=(error.status.respMessage!=undefined && error.status.respMessage.length>0)?error.status.respMessage:error.status.statusDesc;
            if(typeof error.status =='object' && error.status.statusCode!=undefined 
            && (error.status.statusCode?.toLowerCase()=='bx' || error.status.statusCode?.toLowerCase()=='co')){
              //connectionerrormodal:faxMsg
              this.errorMessage=message;
              this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md',backdrop:'static'});
            } else {
              if(message.indexOf(EPA_TRANSACTIon_STATUS.PlanNotSupported)!=-1 && message.indexOf(EPA_TRANSACTIon_STATUS.ContactRx)!=-1){
                //connectionerrormodal: 
                this.errorMessage="Electronic prior authorization cannot be initiated for this <br/> member because the member's plan does not support it.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
                this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md',backdrop:'static'});
              } else {
                //errormodal:message
                this.errorMessage=message;
                this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md',backdrop:'static'});
              }
            }
          }
        } else {
          //errormodal:message
          if(error == EPA_TRANSACTIon_STATUS.INTERNAL_SERVER_ERROR){
            //errorhtmlmodal:
            this.errorMessage="An error occurred. Please call customer service at<br/>1-866-842-3278.";
            this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md',backdrop:'static'});
          } else {
            if(error == EPA_TRANSACTIon_STATUS.POOLING_TIME_EXCEEDED){
              this.errorMessage="The system cannot process your request at this time.<br/><br/>You must submit the prior authorization by fax <br/> or by contacting OptumRx at 800-711-4555.";
              this.modalService.open(this.paInitiationFaxFormTemplateRef,{size:'md',backdrop:'static'});
            } else {
              //errormodal: show error
              this.errorMessage=error;
              this.modalService.open(this.paInitiationErrorTemplateRef, {size:'md',backdrop:'static'});
            }
          }
        }
      }
      goToEPAQuestions():void{
        debugger;
        if(this.epaState == EPA_STATUS.PA_QUESTIONNARIE_TIMELINE){
          this.logger.userAction("View EPA Question Clicked");
          this.modalService.dismissAll();
          this.router.navigateByUrl("/ePA/ePAquestions");
          this.blnepaquestionPage = true;
            sessionStorage.setItem('questionspage',""+this.blnepaquestionPage);
          }
      }
    
      openfaxforms():void{
        this.logger.userAction("View Fax Forms clicked.");
        window.open(environment.faxForm, "_blank");
      }
    
      goToHome():void{
        this.logger.userAction("Routing to Home Page");
        this.router.navigateByUrl("/home");
      }
      dismiss() {
        this.nomatchesFound = "false";
        this.timeoutError = "false";
        this.modalService.dismissAll();
      }
}






